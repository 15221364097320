import { DEFAULT_QUESTION_TYPES } from './constant';
import { randomString } from './utils';

export const sampleQuestionSession = () => {
  return {
    current_session: {
      created_at: new Date(),
      id: randomString(),
      is_complete: false,
      is_first_session: true,
      questions: [
        {
          id: randomString(),
          type: DEFAULT_QUESTION_TYPES.CHOICE,
          text: 'Do you feel seen at work Akshay?',
          answer: null,
          languages: [
            {
              id: 'cloeafmmo000501pgg1dg284i',
              text: 'आपके मित्रों और परिवार के लिए संभावित कार्यस्थल के रूप में नैस्च की अनुशंसा करने की कितनी संभावना है?',
              language: 'clod0453w000601qt39p05gvw',
            },
          ],
          choices: [
            {
              id: randomString(),
              text: 'I do',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'मैं करता हूं',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
            {
              id: randomString(),
              text: 'Sometimes, I want to feel seen more',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'कभी-कभी, मैं और अधिक देखे जाने का अनुभव करना चाहता हूँ',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
            {
              id: randomString(),
              text: 'Never',
              show_reason: true,
              prompt_text: 'Specify the reason',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'कभी नहीं',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
          ],
        },
        {
          id: randomString(),
          type: DEFAULT_QUESTION_TYPES.CHOICE,
          text: 'Do you feel like work is distributed evenly among your team?',
          answer: null,
          languages: [
            {
              id: 'cloeafmmo000501pgg1dg284i',
              text: 'क्या आपको ऐसा लगता है कि काम आपकी टीम के बीच समान रूप से वितरित है?',
              language: 'clod0453w000601qt39p05gvw',
            },
          ],
          choices: [
            {
              id: randomString(),
              text: 'Oh it certainly does',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'ओह, यह निश्चित रूप से होता है',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
            {
              id: randomString(),
              text: "Well sometimes it does, Sometimes it doesn't",
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'खैर कभी-कभी ऐसा होता है, कभी-कभी ऐसा नहीं होता है',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
            {
              id: randomString(),
              text: 'Not that often, actually',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'वास्तव में ऐसा अक्सर नहीं होता',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
          ],
        },
        {
          id: randomString(),
          type: DEFAULT_QUESTION_TYPES.INPUT,
          text: 'Hey Akshay, if we could do one thing to make Mondays more exciting, what would it be?',
          answer: null,
          choices: [],
          languages: [
            {
              id: 'cloeafmmo000501pgg1dg284i',
              text: 'अरे नीना, अगर हम सोमवार को और अधिक रोमांचक बनाने के लिए एक काम कर सकें, तो वह क्या होगा?',
              language: 'clod0453w000601qt39p05gvw',
            },
          ],
        },
        // {
        //   id: randomString(),
        //   type: DEFAULT_QUESTION_TYPES.RATING,
        //   text: 'Rate your organization workforce?',
        //   answer: null,
        // },
        // {
        //   id: randomString(),
        //   type: DEFAULT_QUESTION_TYPES.SLIDER,
        //   text: 'Rate your organization workforce?',
        //   answer: null,
        // },
        // {
        //   id: randomString(),
        //   type: DEFAULT_QUESTION_TYPES.EMOJI,
        //   text: 'Rate your organization workforce?',
        //   answer: null,
        // },
      ],
      title: 'Understanding You',
      description: `Please take a minute to answer the questions below. Your insights will help us better understand the you and your peers.`,
    },
    prev_session: {
      created_at: new Date(),
      id: randomString(),
      is_complete: false,
      is_first_session: false,
      questions: [
        {
          id: randomString(),
          type: DEFAULT_QUESTION_TYPES.CHOICE,
          text: 'Do you feel valued by your employer?',
          answer: null,
          languages: [
            {
              id: 'cloeafmmo000501pgg1dg284i',
              text: 'क्या आप अपने नियोक्ता द्वारा मूल्यवान महसूस करते हैं?',
              language: 'clod0453w000601qt39p05gvw',
            },
          ],
          choices: [
            {
              id: randomString(),
              text: 'I do ',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'मैं करता हूं',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
            {
              id: randomString(),
              text: "Meh, I don't care ",
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'मेह, मुझे परवाह नहीं है',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
            {
              id: randomString(),
              text: 'Not at all',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'बिल्कुल नहीं',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
          ],
        },
        {
          id: randomString(),
          type: DEFAULT_QUESTION_TYPES.INPUT,
          text: 'What motivates you to come to work every day?',
          answer: null,
          languages: [
            {
              id: 'cloeafmmo000501pgg1dg284i',
              text: 'आपको हर दिन काम पर आने के लिए क्या प्रेरित करता है?',
              language: 'clod0453w000601qt39p05gvw',
            },
          ],
          choices: [],
        },
        {
          id: randomString(),
          type: DEFAULT_QUESTION_TYPES.CHOICE,
          text: 'In the last six months, has someone at work talked to you about your progress?',
          answer: null,
          languages: [
            {
              id: 'cloeafmmo000501pgg1dg284i',
              text: 'पिछले छह महीनों में, क्या कार्यस्थल पर किसी ने आपसे आपकी प्रगति के बारे में बात की है?',
              language: 'clod0453w000601qt39p05gvw',
            },
          ],
          choices: [
            {
              id: randomString(),
              text: 'Yes, my manager did',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'हाँ, मेरे प्रबंधक ने किया',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
            {
              id: randomString(),
              text: 'Nobody did! ',
              languages: [
                {
                  id: 'cloeafmmo000501pgg1dg284i',
                  text: 'किसी ने नहीं किया!',
                  language: 'clod0453w000601qt39p05gvw',
                },
              ],
            },
          ],
        },
      ],
      title: 'Understanding You',
      description: `Please take a minute to answer the questions below. Your insights will help us better understand the you and your peers.`,
    },
  };
};

export const sampleNudge = () => {
  return {
    id: randomString(),
    title: "Let's create a roadmap for you",
    message:
      "<p>Primary reason you don't feel seen at work is visibility.You're probably working very hard at work, but there are other small quick things that you could do that'll get you noticed.</p>\r\n\r\n<p>Happy winning! 🤘</p>",
    type: 'C',
    option_text: 'Your response',
    replied_choice: null,
    created_at: '2023-01-27T13:35:00.153134Z',
    updated_at: '2023-02-15T09:30:24.019149Z',
    options: [
      {
        id: randomString(),
        title: 'Speak up in meetings',
        description:
          'This is a quick way to get visible among peers and management.',
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'Strengthen relationship with your boss',
        description: 'Ask for a 1-1 meeting with manager to discuss your goals',
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'Ask for high visibility projects',
        description: `This is industry specific, but if you could get involved in a social project that will get you some PR, it's a great way to get on board.`,
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'Volunteer to represent your team',
        description: `May it be sports, projects or any other social events, always volunteer to represent the team.`,
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'Participate in learning opportunities',
        description: `Company-wide training events, such as seminars or "lunch and learn" sessions, provide great opportunities for you to become better.`,
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'Take initiatives',
        description: `What're you passionate about? Meditation? Football? Just take the initiative to start a mastermind group or clubs for any other activities.`,
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
    ],
  };
};

export const sampleManagerNudge = () => {
  return {
    id: randomString(),
    title: 'Mitigating employee recognition reactions',
    message:
      "<p>Hello, Akshay, looks like more than 70% of the people in your department don't feel recognized at work. <br />We know it could be difficult to make people feel recognized for every small thing they do everyday. It could be tiring. But there are a few ways where you can quickly build a recognition framework while increasing their morale: 🤘</p>",
    type: 'C',
    option_text: 'Your response',
    replied_choice: null,
    created_at: '2023-01-27T13:35:00.153134Z',
    updated_at: '2023-02-15T09:30:24.019149Z',
    options: [
      {
        id: randomString(),
        title:
          'Start a employee of the week/month campaign within your department',
        description: `It's no secret, that so many organizations run this campaign. It works! Build some internal parameters and have an EOM/EOW award for your employees.`,
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'Give a shoutout in the intracom system',
        description:
          'Do you use any communication tool internally like Slack or MS Teams? If yes, then giving shoutout to people from your team for small wins they bring, can go a very long way in recognizing them and keeping them motivated',
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'A word of praise from 2 layers above.',
        description: `Managerial endorsement significantly enhances the impact of appreciating a team member. This recognition suggests to the team member that their boss speaks positively about them to upper management, amplifying the importance of the compliment.`,
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
      {
        id: randomString(),
        title: 'Ask your team members for their opinion',
        description: `Asking someone for their opinion or advice makes them feel important. Not only does it increase their morale, but many time you also end up getting valuable insights from them which are actionable.`,
        option_text: 'Your response',
        replied_choice: null,
        choices: [
          {
            id: randomString(),
            text: 'I plan to do this',
          },
          // {
          //   id: randomString(),
          //   text: 'No',
          // },
          {
            id: randomString(),
            text: 'I already did this',
          },
        ],
      },
    ],
  };
};

export const getDefaultUser = () => {
  return {
    id: 'ckup3g9pp0hw2l81ns501mrky',
    name: 'Nina Dave',
    email: 'nina@nasch.io',
    dial_code: '+91',
    phone_number: '7600661432',
    contact_number: '+91 7600661432',
    company: {
      id: randomString(),
      name: 'Nasch',
      created_at: '2020-10-06T12:06:23.058772Z',
      updated_at: '2023-01-27T13:27:31.641125Z',
      anonymous_survey: true,
      logo: 'https://culturex-static.s3.eu-west-2.amazonaws.com/media/nasch+logo+full.png',
      recognition_enabled: true,
      coins_enabled: false,
      suggestion_enabled: true,
      hrms_type: null,
      languages: [
        {
          id: 'clod0453w000601qt39p05gvw',
          text: 'Hindi',
          code: 'hn',
        },
      ],
    },
    tags: [
      {
        id: randomString(),
        name: 'Agra',
        tag_type: 'G',
        label: 'Agra Loc',
        created_at: '2021-09-09T06:22:33.769076Z',
        updated_at: '2021-09-09T06:22:33.769100Z',
      },
    ],
    gender: 'M',
    job_title: 'Developer Advocate',
    job_role: 1,
    job_start_date: null,
    is_active: true,
    date_joined: '2022-07-07T13:03:48.555481Z',
    updated_at: '2022-09-21T06:47:44.247886Z',
    country: '',
    total_coins: 49,
    language: null,
  };
};

export const getUsersList = () => {
  return [
    {
      id: 'lom6g1k6',
      name: 'John Doe',
      email: 'john@nasch.io',
      job_title: 'CEO',
      thread_tag: { id: 6, name: 'Executive' },
    },
    {
      id: '7d99d03m',
      name: 'Steve Smith',
      email: 'steve@nasch.io',
      job_title: 'Tech Lead',
      thread_tag: { id: 6, name: 'Technology' },
    },
    {
      id: 'i37gmgae',
      name: 'Mary Tae',
      email: 'mary@nasch.io',
      job_title: 'Software Engineer',
      thread_tag: { id: 6, name: 'Engineer' },
    },
    {
      id: '4a7lb9j8',
      name: 'Luke De',
      email: 'luke@nasch.io',
      job_title: 'DevOps Engineer',
      thread_tag: { id: 6, name: 'Engineer' },
    },
    {
      id: randomString(),
      name: 'Luy Tell',
      email: 'luy@nasch.io',
      employee_code: 'EMP03',
      job_title: 'Senior Developer',
      thread_tag: { id: 6, name: 'Technology' },
    },
    {
      id: randomString(),
      name: 'Veni Yu',
      email: 'veni@nasch.io',
      employee_code: 'EMP02',
      job_title: 'Developer',
      thread_tag: { id: 6, name: 'Technology' },
    },
  ];
};

export const getTagsList = () => {
  return [
    {
      id: randomString(),
      label: 'Acknowledge',
      value: 'Acknowledge',
      name: 'Acknowledge',
    },
    {
      id: randomString(),
      label: 'Recognize',
      value: 'Recognize',
      name: 'Recognize',
    },
  ];
};

export const getOccasionList = () => {
  return [
    { name: 'Birthday', label: 'Birthday', value: 'mfg36e1i', id: 'mfg36e1i' },
    {
      name: 'Anniversary',
      label: 'Anniversary',
      value: 'g4p56c1o',
      id: 'g4p56c1o',
    },
  ];
};

export const getRecognitionComments = () => {
  return [
    {
      id: 'clpvaifm6000bbbi3gvlmwu3q',
      message: 'Great to hear this @Kevin! Way to go!',
      parent_id: 'clput9ko40003gui3l11h38tg',
      created_at: '2023-12-07T14:25:12.078936Z',
      user: {
        id: 'cktcjlwr80ekh01mryech618b',
        name: 'John Smith',
        email: 'john@nasch.io',
        dial_code: '+91',
        phone_number: '9638006732',
        contact_number: '+91 9638006732',
        label: 'John Smith',
        value: 'cktcjlwr80ekh01mryech618b',
      },
      reaction: {
        users: {
          1: [],
          2: [],
          3: [],
        },
        count: {
          1: 0,
          2: 0,
          3: 0,
        },
      },
      total_reply: 0,
      tags: [],
      reaction: {
        users: {
          1: [{ id: randomString(), name: 'Kevin' }],
          2: [],
          3: [],
        },
        count: {
          1: 1,
          2: 0,
          3: 0,
        },
      },
      mentioned_users: [
        {
          id: 'cktcjlwr80ekh01mryech618b',
          name: 'Kevin',
          email: 'kevin@nasch.io',
          job_title: 'Senior Software Engineer',
        },
      ],
    },
  ];
};

export const getRecognitionMessage = () => {
  const date1 = new Date();
  const date2 = date1.setDate(date1.getDate() - 2);
  return [
    {
      id: randomString(),
      created_at: date2,
      total_reply: 2,
      user: {
        id: randomString(),
        name: 'Nina Cruise',
        email: 'nina@nasch.io',
        job_title: 'Team Lead',
      },
      message: `@Kevin I just showed the new Text Analytics product to my client and they were BLOWN AWAY. Unbelievable job managing this release and adding huge value for our customer!`,
      reaction: {
        users: {
          1: [
            { id: randomString(), name: 'Twinkle Dwivedi' },
            { id: randomString(), name: 'Aslam Shaikh' },
            { id: randomString(), name: 'Kevin' },
            { id: randomString(), name: 'Belinda Pruneda' },
            { id: randomString(), name: 'Peter' },
          ],
          2: [
            { id: randomString(), name: 'Twinkle Dwivedi' },
            { id: randomString(), name: 'Aslam Shaikh' },
            { id: randomString(), name: 'Kevin' },
          ],
          3: [
            { id: randomString(), name: 'Twinkle Dwivedi' },
            { id: randomString(), name: 'Aslam Shaikh' },
            { id: randomString(), name: 'Kevin' },
          ],
        },
        count: {
          1: 5,
          2: 3,
          3: 3,
        },
      },
      values: [
        { id: randomString(), name: 'Appreciation' },
        { id: randomString(), name: 'Greetings' },
      ],
      mentioned_users: [
        {
          id: 'cktcjlwr80ekh01mryech618b',
          name: 'Kevin',
          email: 'kevin@nasch.io',
          job_title: 'Senior Software Engineer',
        },
      ],
    },
    {
      id: randomString(),
      created_at: new Date(),
      user: {
        id: randomString(),
        name: 'Nina Cruise',
        email: 'nina@nasch.io',
        job_title: 'Team Lead',
      },
      receiver: {
        id: randomString(),
        name: 'Kevin',
        email: 'kevin@nasch.io',
        job_title: 'CEO',
      },
      message: `Wished on Akshay on their Birthday with the following message: \n Have a great life ahead`,
      reaction: {
        users: { 1: [], 2: [], 3: [] },
        count: { 1: 0, 2: 0, 3: 0 },
      },
      values: [{ id: 'pdj8bjg0', name: 'Birthday' }],
      occasion: { id: 'mfg36e1i', name: 'Birthday' },
      mentioned_users: [],
    },
  ];
};

export const getBehaviourQuestions = () => {
  return {
    id: 'cly7nifs7000acgi3g00515ai',
    created_at: '2024-07-04T19:20:00.104196Z',
    is_complete: false,
    survey: {
      id: 'cly7nifqz0002cgi37bo8spzf',
      created_at: '2024-07-04T19:20:00.059912Z',
      name: 'Nasch_behaviour_survey',
      title: 'Alignment Survey',
      description:
        "Please choose your peer(s) and rate them in accordance with your organization's core values",
      questions: [
        {
          id: 'cly7nifr60003cgi3niwv9flh',
          text: 'Being Transparent & Open to Learn',
          type: 8,
          options: [
            '1 - Unsatisfactory',
            '2 - Improvement Needed',
            '3 - Meets Expectations',
          ],
          show_reason: true,
          prompt_text: 'Specify the reason',
          tooltip_text:
            'Toolitip Behaviour 1 - Being Transparent & Open to Learn',
          languages: [],
        },
        {
          id: 'cly7nifrf0004cgi3n0sex1o2',
          text: 'Open to Learn',
          type: 8,
          show_reason: true,
          prompt_text: 'Specify the reason',
          tooltip_text: 'Tooltip Behaviour 2 - Open to Learn',
          languages: [],
        },
        {
          id: 'cly7nifrj0005cgi3yiic8nkb',
          text: 'Work with a Shared Vision',
          type: 8,
          show_reason: false,
          tooltip_text:
            'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
          languages: [],
        },
        {
          id: 'cly7nifrn0006cgi3g6nrbck6',
          text: "Behaviour 4 - Leverage your Team's Strength",
          type: 8,
          show_reason: false,
          tooltip_text:
            'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
          languages: [],
        },
        {
          id: 'cly7nifrr0007cgi36nn6hgfw',
          text: 'Collaboration Hygiene',
          type: 2,
          tooltip_text: '',
          languages: [],
        },
        {
          id: 'cly7nifru0008cgi3u8nr3a0w',
          text: 'Open Feedback Comment Box',
          type: 2,
          tooltip_text: '',
          languages: [],
        },
      ],
    },
    answers: [],
  };
};
