import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';

// components
import { Button } from 'antd';
import FullPageLoader from '../../components/FullPageLoader';

// icons
import { DownloadOutlined } from '@ant-design/icons';

// utils
import { useSession } from '../../Providers/SessionProviders';

const CertificateWrapper = styled.div`
  // width: 800px;
  max-width: 900px;
  margin: 20px auto 0px auto;
  height: 600px;
  background: #c10d0d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const CertificateContent = styled.div`
  width: 90%;
  height: 90%;
  background: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-top-right-radius: 90px;
  border-bottom-left-radius: 90px;
  position: relative;
`;

const Ribbon = styled.div`
  position: absolute;
  top: -45px;
  left: 60px;
  width: 50px;
  height: 140px;
  background: black;
  // clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0% 80%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 50% 65%, 0% 80%);
`;

const Title = styled.h1`
  font-family: Garamond;
  font-size: 32px;
  font-weight: bold;
  margin-top: 50px;
  line-height: 16px;
`;

const Subtitle = styled.p`
  width: 80%;
  margin: auto;
  font-size: 18px;
  margin: 30px 0px;
  // font-family: 'Times New Roman', serif;
`;

const EmployeeName = styled.h2`
  font-size: 50px;
  font-weight: bold;
  margin: 0px;
  font-family: 'Times New Roman', serif;
`;

const Footer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  padding-top: 10px;
  // font-family: 'Times New Roman', serif;
`;

const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Line = styled.div`
  width: 200px; /* Adjust as needed */
  height: 1px;
  background-color: #333;
  margin: 5px 0px;
  color: #333;
`;

const StyledButton = styled(Button)`
  :hover {
    color: #fff;
  }
`;

const JourneyCertificate = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useSession();
  const certificateRef = useRef();
  const [loading, setLoading] = useState(true);
  const [completionDate, setCompletionDate] = useState(null);

  useEffect(() => {
    setLoading(false);
    setCompletionDate(new Date());
  }, []);

  const downloadPDF = () => {
    const input = certificateRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190; // Adjust width
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
      pdf.save('certificate.pdf');
    });
  };

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <StyledButton
        style={{
          position: 'absolute',
          margin: '30px 0px 0px',
          right: currentUser?.company?.suggestion_enabled ? '20px' : '210px',
        }}
        ghost
        icon={<DownloadOutlined />}
        onClick={downloadPDF}
      />
      <CertificateWrapper ref={certificateRef}>
        <CertificateContent>
          <Ribbon />
          <Title>CERTIFICATE OF COMPLETION</Title>
          <Subtitle>This certifies that</Subtitle>
          <EmployeeName>{currentUser?.name || '<Employee Name>'}</EmployeeName>
          <Subtitle>
            has successfully completed the Journey – New Employee Onboarding
            with Fair & Square.
          </Subtitle>
          <Footer>
            <FooterItem>
              <span>Signature</span>
              <Line />
              <strong>Steve Mak</strong>Head of People Development
              <span></span>
            </FooterItem>
            <FooterItem style={{ marginTop: '-15px' }}>
              <span>{moment(completionDate).format('LL')}</span>
              <Line />
              <strong>Date of Completion</strong>
            </FooterItem>
          </Footer>
        </CertificateContent>
      </CertificateWrapper>
    </>
  );
};

export default JourneyCertificate;
